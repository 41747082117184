import * as React from 'react';

import Exhibit from '../../components/Exhibit';
import Shell from '../../components/Shell';

export default () => {
    return (
        <Shell>
            <div className="exhibition-page">
                <Exhibit
                    images={[]}
                    title="Bugs"
                    subtitles={[
                        'Every Creature has a story to tell.',
                        'Welcome a Bug into your world and start your colourful creature collection today through',
                        'a custom print or a unique commission.',
                    ]}
                />
            </div>
        </Shell>
    );
};
